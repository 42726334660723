import React from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import { Subtitle } from "../components/Subtitle";
import { Contact } from "../components/Contact";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const IndexPage = () => (
  <Page>
    <Row>
      <Col>
        <Title>Liukastumis&shy;varoitus&shy;palvelu</Title>
      </Col>
    </Row>

    <Row>
      <Col md={9}>
        <Subtitle>
          Liukkaasta kelistä varoitetaan tekstiviestillä, palvelu on
          varoitettaville ilmainen
        </Subtitle>

        <Content>
          Varoituspalvelusta on saatu erinomaista palautetta. Pienellä
          panostuksella saadaan moninkertainen hyöty.
        </Content>

        <Content>
          Varoitusviestin lähettää joko talvihoidon päivystäjä samalla, kun
          hälytetään myös hiekoittajat töihin tai se lähetetään keliennusteiden
          perusteella, mikäli varoittaja kokemuksensa mukaan pitää vaarallisen
          liukkauden syntyä käytännössä varmana. Lähtökohtaisesti varoitus
          perustuu todelliseen tilanteeseen.
        </Content>

        <Content>
          Tekstiviestivaroituspalvelu on saanut liikenneturvan kunniakirjan
          liikenneturvallisuuden hyväksi tehdystä, uutta teknologiaa
          hyödyntävästä työstä sekä kotitapaturmien ehkäisykampanjan
          turvallisuusteko-kunniamaininnan.
        </Content>

        <Content>
          Katso tämän hetken lukumäärät:{" "}
          <a href="/varoitukset">Varoitettavat ja varoitukset</a>
        </Content>

        <Content>
          Palvelusta kiinnostuneet kunnat voivat ottaa yhteyttä palvelun
          koordinaattoriin: <Contact />
        </Content>
      </Col>

      <Col md={3}>
        <Subtitle>Ilmoittaudu palveluun</Subtitle>

        <Content>
          Klikkaamalla kaupungin nimeä, saat ilmoittautumisohjeet palveluun:
        </Content>

        <Content as="ul">
          <li>
            <a href="/ilmoittautuminen/helsinki">Helsinki</a>
          </li>
          <li>
            <a href="/ilmoittautuminen/lahti">Lahti</a>
          </li>
          <li>
            <a href="/ilmoittautuminen/oulu">Oulu</a>
          </li>
          <li>
            <a href="/ilmoittautuminen/kuopio">Kuopio</a>
          </li>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default IndexPage;
